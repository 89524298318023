import * as entities from "entities"
import {useTranslation} from "react-i18next";
import {MouseEvent, useState} from "react";
import DynamicClientOnly from "@/Components/Common/DynamicClientOnly";
import "./ArticleContent.scss";

function closestElement(element: HTMLElement | null, selector: string) {
    while (element && element !== document.body) {
        if (element.matches(selector)) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
}

export default function (props: {
    content: string,
}) {
    const {t} = useTranslation()
    const [viewerVisible, setViewerVisible] = useState(false)
    const [viewerImgs, setViewerImgs] = useState<{
        src: string,
    }[]>([])

    const computedContent = (content: string) => {
        content = content || ''

        content = content.replace(/>(.+?)<\//g, (str) => {
            return t(str)
        })
        return entities.decodeHTML(content)
    }

    const handleImgClick = (img: HTMLImageElement) => {
        setViewerImgs([{
            src: img.src
        }])
        setViewerVisible(true)
    }

    const onClick = (e: MouseEvent) => {
        if (e.target instanceof HTMLImageElement) {
            const closestDiv = closestElement(e.target, 'a');
            if (closestDiv) {
                return
            }

            handleImgClick(e.target)
        }
    }

    return <>
        <div onClick={onClick} className="article-content"
             dangerouslySetInnerHTML={{__html: computedContent(props.content)}}></div>
        <DynamicClientOnly loader={() => import('./Common/Viewjs')}
                           onClose={() => setViewerVisible(false)}
                           images={viewerImgs}
                           visible={viewerVisible}
                           viewerConfig={
                               {
                                   title: false,
                                   zoomable: true,
                                   rotatable: false,
                                   scalable: false,
                                   movable: false,
                                   fullscreen: false,
                               }

                           }
        />

    </>
}